@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Aliceblue Tradestore Admin/Vendor
Version:    2.0
Created:    23.05.2019 07:00 PM
Use:   Product store
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Fonts import, Typography
2. Colors
3. Common styles
4. Page loader / .page-loader
5. Global styles
6. Buttons and CTA
7. Forms fields
8. Animations

-------------------------------------------------------------------*/

/*------------------------------------------------------------------

1. Fonts import, Typography

-------------------------------------------------------------------*/
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Black.otf");
  font-weight: 900;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-ExtraBold.otf");
  font-weight: 800;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Bold.otf");
  font-weight: 700;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-SemiBold.otf");
  font-weight: 600;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-MediumLegacy.otf");
  font-weight: 500;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-RegularLegacy.otf");
  font-weight: 400;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Light.otf");
  font-weight: 300;
}

/*@font-face {
  font-family: HKGrotesk-ExtraBold;
  src: url(../font/hk-grotesk/HKGrotesk-ExtraBold.otf);
  font-weight: 900;
}
@font-face {
  font-family: HKGrotesk-Bold;
  src: url(../font/hk-grotesk/HKGrotesk-Bold.otf);
  font-weight: 800;
}
@font-face {
  font-family: HKGrotesk-SemiBold;
  src: url(../font/hk-grotesk/HKGrotesk-SemiBold.otf);
  font-weight: 700;
}
@font-face{
  font-family: HKGrotesk-Medium;
  src: url(../font/hk-grotesk/HKGrotesk-Medium.otf);
  font-weight: 400;
}
@font-face {
  font-family: HKGrotesk-Regular;
  src: url(../font/hk-grotesk/HKGrotesk-Regular.otf);
  font-weight: 300;
}
@font-face {
  font-family: HKGrotesk-Light;
  src: url(../font/hk-grotesk/HKGrotesk-Light.otf);
  font-weight: 200;
}*/
/*@font-face { font-family: Gilroy-Bold; src: url(../fonts/Gilroy-Bold.woff); }
@font-face { font-family: Gilroy-Light; src: url(../fonts/Gilroy-Light.woff); }
@font-face { font-family: Questrial; src: url(../fonts/Questrial-Regular.ttf); }*/

/*@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&display=swap');*/

body,html{
  font-family: 'HK Grotesk', sans-serif;
  font-size: 15px;
  font-weight: 300;
  overflow-x: hidden;
  background: #fcfcfc;
  scroll-behavior: smooth;
}
header{
  background: #fff;
}
h1{
  font-family: 'HK Grotesk', sans-serif;
  font-size: 48px;
  line-height: 1.04;
  margin-bottom: 0;
  padding: 15px 0;
}
h2{
  font-family: 'HK Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
h2 span{
  color: #000;
  font-weight: 600;
}
h3{
  font-family: 'HK Grotesk', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #3e3e3e;
  margin: 0;
}
h4{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}
h5{
  font-size: 21px;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 0;
}
h6{
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
p{
  font-size: 15px;
}
a{
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
}
a:hover{
  text-decoration: none;
}
input{
  font-size: 15px;
}
::-webkit-input-placeholder, ::placeholder {
  font-size: 15px;
}
.cursor-pointer{
  cursor: pointer;
}
.e-f-weight-100{font-weight: 100;}
.e-f-weight-300{font-weight: 300 !important;}
.e-f-weight-400{font-weight: 400 !important;}
.e-f-weight-500{font-weight: 500 !important;}
.e-f-weight-600{font-weight: 600 !important;}
.e-f-weight-900{font-weight: 900 !important;}
.e-letter-space{ letter-spacing: 4px;}
.e-letter-space-2{ letter-spacing: 2px;}
.e-double-l-height{line-height: 200%;}
.e-sec-head{
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 600;
}
.e-s-txt{
  font-size: 13px;
  font-weight: 300;
}
.e-card-p-txt{
  font-size: 14px;
  line-height: 160%;
}
.e-x-s-txt{
  font-size: 10px;
}
.e-xx-s-txt{
  font-size: 8px;
}
.e-nav-item{
  font-size: 16px;
  cursor: pointer;
}
.e-font-12{
  font-size: 12px;
}
.e-font-13{
  font-size: 13px;
}
.e-font-14{
  font-size: 14px;
}
.e-font-15{
  font-size: 15px;
}
.e-font-16{
  font-size: 16px;
}
.e-font-18{
  font-size: 18px;
}

/*------------------------------------------------------------------

2. Colors

-------------------------------------------------------------------*/
.e-c-primary{color: #21418a !important;}
.e-c-secondary{color: #003370 !important;}
.e-c-dull-secondary{color: #8da4ff}
.e-c-white{color: #ffffff !important;}
.e-c-black{color: #000000 !important;}
.e-c-grey{color: #828282;}
.e-c-brown{color: #474747;}
.e-c-green{color: #0edb30;}
.e-c-yellow{color: #ffc000;}
.e-c-red{color: #fe1800 !important;}
.e-c-medium-grey{color: #b7b7b7;}

.e-bg-primary{background: #ed4c14;}
.e-bg-secondary{background: #003370;}
.e-bg-black{background: #000000;}
.e-bg-white{background: #ffffff !important;}
.e-bg-light-gray{background: #fcfcfc}
.e-bg-drak-gray{background: #131313;}


/*------------------------------------------------------------------

3. Common styles

-------------------------------------------------------------------*/
.e-align-center{ text-align:center !important; }
.e-align-left{ text-align:left !important;}
.e-align-right{ text-align:right !important; }
.e-f-left{float:left !important;}
.e-f-right{float:right !important;}
.e-relative{position:relative;}
.e-absolute{position:absolute;}
.e-fixed{position:fixed;}
.e-hidden{display:none;}
.e-inline-block{display:inline-block;}
.e-full-height{height: 100%;}
.e-full-width{width: 100%;}
.e-display-block{display: block;}
.e-uppercase{text-transform: uppercase;}
.e-w-80{width: 80%!important;}
.e-w-90{width: 90%!important;}
.e-vh-30{height: 30vh;}
.e-vh-40{height: 40vh;}
.e-vh-50{height: 50vh;}
.e-h-80{height: 80vh;}
.e-h-85{height: 85vh;}
.e-h-90{height: 90vh;}
.e-border-5{border-radius: 5px !important;}
.e-border-8{border-radius: 8px;}
.e-border-100{border-radius: 100%;}
.e-txt-underline{text-decoration: underline;}
.e-hide{display: none;}
.e-show{display: auto;}
.e-no-transition{transition: none !important;}
.e-mobile-hide{display: none;}
.e-desktop-hide{display: auto;}
.e-max-width-full{max-width: 100%;}
.e-txt-justify{text-align: justify;}
.e-no-border{border: 0 !important;}

.e-box-shadow-1{box-shadow: 0px 13px 15px 0 rgba(99, 99, 99, 0.1);}
.bg-gradient{
  background-image: -webkit-linear-gradient(to right, #2e42b6, #298eed);
  background-image: linear-gradient(to right, #2e42b6, #298eed);
}

.e-p-0{padding: 0 !important;}
.e-p-right-0{padding-right: 0 !important;}
.e-p-left-0{padding-left: 0 !important;}
.e-p-bottom-05{padding-bottom: 5px !important;}
.e-p-bottom-10{padding-bottom: 10px !important;}
.e-p-bottom-15{padding-bottom: 15px !important;}
.e-p-bottom-20{padding-bottom: 20px;}
.e-p-bottom-30{padding-bottom: 30px}
.e-p-bottom-40{padding-bottom: 40px;}
.e-p-bottom-50{padding-bottom: 50px;}
.e-p-bottom-100{padding-bottom: 100px}
.e-p-right-10{padding-right: 10px;}
.e-p-right-20{padding-right: 20px;}
.e-p-right-30{padding-right: 30px;}
.e-p-left-10{padding-left: 10px;}
.e-p-left-20{padding-left: 20px;}
.e-p-left-30{padding-left: 30px;}
.e-p-left-40{padding-left: 40px;}
.e-p-left-50{padding-left: 50px;}
.e-p-top-05{padding-top: 5px;}
.e-p-top-10{padding-top: 10px;}
.e-p-top-20{padding-top: 20px;}
.e-p-top-30{padding-top: 30px;}
.e-p-top-40{padding-top: 40px;}
.e-p-top-50{padding-top: 50px;}
.e-p-top-100{padding-top: 100px;}
.e-p-top-120{padding-top: 120px;}
.e-p-top-130{padding-top: 130px;}
.e-p-equal-10{padding: 10px;}
.e-p-equal-15{padding: 15px;}
.e-p-equal-20{padding: 20px;}
.e-p-equal-30{padding: 30px;}
.e-p-equal-40{padding: 40px;}
.e-p-equal-50{padding: 50px;}

.e-m-0{margin:0 !important;}
.e-m-left-0{margin-left:0 !important;}
.e-m-right-0{margin-right:0 !important;}
.e-m-bottom-0{margin-bottom: 0px !important;}
.e-m-bottom-10{margin-bottom: 10px;}
.e-m-bottom-15{margin-bottom: 15px;}
.e-m-bottom-20{margin-bottom: 20px;}
.e-m-bottom-30{margin-bottom: 30px !important;}
.e-m-bottom-40{margin-bottom: 40px;}
.e-m-bottom-50{margin-bottom: 50px;}
.e-m-top-10{margin-top: 10px;}
.e-m-top-15{margin-top: 15px;}
.e-m-top-20{margin-top: 20px;}
.e-m-top-30{margin-top: 30px;}
.e-m-right-05{margin-right: 5px;}
.e-m-right-10{margin-right: 10px !important;}
.e-m-right-20{margin-right: 20px !important;}
.e-m-right-30{margin-right: 30px;}
.e-m-left-10{margin-left: 10px;}
.e-m-left-20{margin-left: 20px;}
.e-m-left-30{margin-left: 30px;}
.e-m-top-100{margin-top: 100px;}
.e-m-top-120{margin-top: 120px;}
.e-m-top-130{margin-top: 130px;}
.e-m-equal-15{margin: 15px;}




/*------------------------------------------------------------------

4. Page loader / .page-loader

-------------------------------------------------------------------*/
.e-loading-screen{
  height: 100vh;
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
}
.e-loading-screen img{
  max-width: 200px;
}


/*------------------------------------------------------------------

5. Global styles

-------------------------------------------------------------------*/
/*links and Links hover*/
a, .e-linktext{
  letter-spacing: 0.05em;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #0461D1;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  transition: all .3s ease;
}
a:hover{
  color: #21418a;
}
.e-linktext:hover{
  color: #21418a;
}
a.e-underline{
  text-decoration: underline;
}
a.e-underline:hover{
  text-decoration: underline;
}
a.e-c-primary:hover{
  color: #000 !important;
}

/*Scroll bar*/
::-webkit-scrollbar{width: 0px !important;}
::-webkit-scrollbar-track{background: #ccc;}
::-webkit-scrollbar-thumb{background: #003370;}
::-webkit-scrollbar-thumb:hover{background: #555;}

/* Banner */
.e-banner-head span{
  background-image:
  linear-gradient(to right, #2e42b6, #298eed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*Buttons*/
.e-p-btn{
  border-radius: 4px;
  background-color: #0461D1;;
  padding: 8px 25px;
  font-weight: 500;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  font-size: 13px;
  border: 0 !important;
}
.e-p-btn.e-inActive{
  opacity: .4;
  cursor: not-allowed;
}
.e-p-btn.e-white{
  background-color: #ffffff;
}
.e-p-btn:hover{
  background: #239fdb;
  color: #fff;
  transition: all .6s ease;
}
.e-p-btn.e-in-active{
  opacity: .5;
  cursor: not-allowed;
}
.e-p-btn.e-in-active:hover{
  background-color: #0461D1;;
}
.e-s-btn{
  color: #000000;
  font-size: 13px;
  font-weight: 600;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  transition: all .2s ease;
}
.e-s-btn:hover{
  color: #21418a;
  border-bottom: 1px solid #21418a;

}
button:focus{
  border: 0;
  outline: none;
}
.e-s-btn.e-inActive{
  opacity: .4;
  cursor: not-allowed !important;
}
.e-r-btn {
  border-radius: 4px;
  color: red;
  border: 1px solid red;
  padding: 6px 10px;
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}



/*Modal*/
.modal-open .modal{
  background: rgba(255, 255, 255, .6);
}
.modal-dialog, .modal-content{
  box-shadow: 0 0 60px 0 rgba(163, 163, 165, 0.1);
  border: solid 0.5px rgba(207, 207, 207, 0.22);
  border-radius: 5px;
}
.modal-content{
  padding: 15px;
}
.modal-header{
  border: 0;
}
.modal-backdrop {
   background-color: rgba(255, 255, 255, 0.8);
}
.ev-large-modal{
  max-width: 730px;
}
.e-modal-select .css-bg1rzq-control{
  border-color: #00000020 !important;
  background: #fff !important;
  min-height: 42px !important;
}

/* Input box */
input, textarea{
  box-shadow: none !important;
}
.ev-input-content-wrap {
  position: relative;
}
.ev-input-content-wrap .ev-input-contentbox {
  position: relative;
  width: 100%;
}
.ev-input-content-wrap .ev-input-contentbox input, .ev-input-content-wrap .ev-input-contentbox textarea{
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  line-height: 0px;
  font-size: 14px!important;
  font-weight: 500;
  border: 0;
  background: #fff;
  border: .5px solid #00000020;
  outline: none;
  border-radius: 5px;
  -webkit-appearance: none;
  padding: 25px 15px;
 /*color: #999999;*/
  color: #2e2e2e;

}
.ev-input-content-wrap .ev-input-contentbox textarea{
  min-height: 120px;
  line-height: 150%;
}
input:focus{
  outline: none;
}
::-webkit-input-placeholder, ::placeholder {
  font-size: 14px;
  font-weight: 400;
}
.ev-small-input-contentbox input{
  padding: 24px 15px !important;
}
.ev-small-input-contentbox label{
  top: 13px !important;
}
.ev-small-input-contentbox input:focus ~ label{
  /*-webkit-transform: translateY(-25px) !important;
  transform: translateY(-25px) !important;*/

}
input[type=color], input[type=file]{
  opacity: 0;
  width: 1px;
  height: 1px;
  display: block;
}

/*Table*/
.table thead th{
  border: none;
  font-weight: 500;
}
.table{
  border-radius: 5px;
}
.table td .e-s-btn{
  font-weight: 500;
}
.table{
  margin-bottom: 0;
}
.table-striped tbody tr:nth-of-type(odd){
  background-color: rgba(165, 165, 165, 0.05);
}

/* Checkbox */
.e-checkbox-wrap {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 16px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.e-checkbox-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #000;
}
.e-checkbox-wrap:hover input ~ .checkmark {
  background-color: #fff;
  border: 1px solid #ccc;
}
.e-checkbox-wrap input:checked ~ .checkmark {
  background-color: #f0f4ff;
  border: 1px solid #407BFF;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.e-checkbox-wrap input:checked ~ .checkmark:after {
  display: block;
}
.e-checkbox-wrap .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid #407BFF;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Main Content*/
.e-main-content{
  background: #fcfcfc;
  padding-top: 42px;
  min-height: 90vh;
}
.e-no-result{
  width: 120px;
  padding-bottom: 20px;
}

/*Placeholders*/
.e-placeholder-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 380px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 3px;
}
.e-placeholder-content {
  position: relative;
  height: 100%;
  -webkit-animation: placeHolderSwept 1s infinite linear forwards;
  -moz-animation: placeHolderSwept 1s infinite linear forwards;
  -o-animation: placeHolderSwept 1s infinite linear forwards;
  animation: placeHolderSwept 1s infinite linear forwards;
  background: #ebebec;
  background-image: linear-gradient(to right, #ebebec 0%, #e0e0e2 20%, #ebebec 40%, #ebebec 100%);
  background-repeat: no-repeat;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #efefef;
}
@-webkit-keyframes placeHolderSwept {
  0% {
    background-position: -400px 0
  }
  100% {
    background-position: 400px 0
  }
}
@-moz-keyframes placeHolderSwept {
  0% {
    background-position: -400px 0
  }
  100% {
    background-position: 400px 0
  }
}
@-o-keyframes placeHolderSwept {
  0% {
    background-position: -400px 0
  }
  100% {
    background-position: 400px 0
  }
}
@keyframes placeHolderSwept {
  0% {
    background-position: -400px 0
  }
  100% {
    background-position: 400px 0
  }
}

.e-card1-timeline-item {
  background: #fff;
  border: 1px solid;
  border-color: #e5e6e996 #dfe0e48c #d0d1d58f;
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto;
  max-width: 472px;
  min-height: 200px;
  z-index: 1;
  position: relative;
}
.e-placeholder-one-large, .e-placeholder-one-small{
  background: #fff;
  border: 1px solid;
  border-color: #e5e6e996 #dfe0e48c #d0d1d58f;
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto;
  min-height: 300px;
  z-index: 1;
  position: relative;
}
.e-placeholder-one-large .e-card1-animated-background{
  height: 300px;
}
.e-placeholder-one-small{
  min-height: 200px;
}
.e-placeholder-one-small .e-card1-animated-background{
  height: 200px;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.e-card1-animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 180px;
  position: relative;
}

.Toastify__toast{
  padding: 15px !important;
}
.Toastify__close-button{
  display: none !important;
}
.Toastify__toast-body{
  padding: 10px 40px !important;
  background-image: url("../images/exclamation-mark.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: left;
}
.Toastify__toast--default{
    color: #232323 !important;
}
.e-card-outer-wrap{
  display: -webkit-flex;
  flex-wrap: wrap;
}
.e-card-outer-wrap a:hover{
  color: #fff !important;
}
.e-error{
  background-color: #f5c0c0;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 13px;
  color: #d40000;
  font-weight: 500;
  border: 1px solid #d40000;
  text-transform: capitalize;
}



/*------------------------------------------------------------------

6. Animations

-------------------------------------------------------------------*/
.e-card-transition{
 /* cursor: pointer;*/
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}
.e-card-transition:hover{
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  border-color: transparent;
}
.e-text-underline{
  height: 13px;
  text-decoration: none;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
}
.e-text-underline:after{
  transition: all ease-in-out .2s;
  background: none repeat scroll 0 0 #0b5ecd;
  content: "";
  display: block;
  height: 3px;
  width: 0;
}
.e-text-underline:hover:after, .e-text-underline.e-active:after{
  width: 100%;
}
.anchor{
  cursor: pointer;
}
.anchor:hover{
  text-decoration: underline;
}